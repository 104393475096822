
import { defineComponent } from 'vue'
import avatar from './cpns/avatar.vue'
import { useStore } from '@/store'
import CourseCard from '../course/cpns/course-card.vue'
import TrainingCard from '../training/cpns/training-card.vue'
import Empty from '@/components/empty'
import { ICourseItem } from '@/service/course/types'
import { ITrainingItem } from '@/store/training/types'
import hhRequest from '@/service'

export default defineComponent({
  name: 'Profile',
  components: { Empty, TrainingCard, CourseCard, avatar },
  async setup() {
    const {
      state: { login }
    } = useStore()
    const user = login.user

    //学习计划
    const courses = await hhRequest.get<ICourseItem[]>({ url: '/user/course' })
    //历史记录
    const histories: any[] = []
    //我的证书
    const certificates: any[] = await hhRequest.get<ICourseItem[]>({
      url: '/user/certificate'
    })
    //我的班级
    const classes = await hhRequest.get<ITrainingItem[]>({
      url: '/user/training'
    })

    return {
      user,
      courses,
      histories,
      certificates,
      classes
    }
  }
})
